import { config } from '../config';

const GetAPI = async (endpoint) => {
  try {
    const response = await fetch(`${config.API_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Network response was not ok');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error in GetAPI:', error);
    throw error;
  }
};

export default GetAPI;