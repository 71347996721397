import { config } from 'config';
import GetAPI from 'functions/GetAPI';
import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaClock, FaExclamationCircle, FaMapMarkerAlt, FaTimesCircle, FaUserTie, FaSpinner, FaBan } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ApplyJobApplication = () => {
  const { uuid, applied_from } = useParams();
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState({});
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchJobDetails = async () => {
      const response = await GetAPI(`rts/apply/job/job_applications/${uuid}/get_jd_Job_uuid`);
      setJobDetails(response.data.job);
    };
    fetchJobDetails();
  }, [uuid]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const handleAgree = async () => {
    if (!validateEmail(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    const appliedData = JSON.parse(localStorage.getItem('appliedData')) || {};
    const appliedTime = appliedData[`${uuid}_${email}`];

    const now = new Date().getTime();
    if (appliedTime && now - appliedTime < 3600000) {
      toast.error('You have already applied for this position. Please check your email and try again later.');
      return;
    }

    try {
      setIsLoading(true); // Start loading
      const response = await GetAPI(`rts/jobs/share/${jobDetails.job_id}/${email}`);
      toast.success('Please check your email. We have sent you further details to proceed.');

      localStorage.setItem('appliedData', JSON.stringify({
        ...appliedData,
        [`${uuid}_${email}`]: now
      }));
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
    } finally {
        
        navigate('/');
        setEmail('')
      setIsLoading(false);
    }
  };
  
  return (
    <div className="max-w-3xl mx-auto p-8 bg-white shadow-md rounded-md">
      <a href="/">
        <img
          src={`${config.IMAGE_URL}branding/logo1.png`}
          alt="Company Logo"
          className="w-36 h-auto mb-4 transition-opacity duration-700 ease-out hover:opacity-75"
        />
      </a>

      <h1 className="text-2xl font-bold mb-4">{jobDetails.job_title}</h1>

      <JobAdditionalDetails job={jobDetails} />
     
{jobDetails.job_status != 'Open' ? 
 <div className="flex items-center bg-red-100 text-red-700 p-4 my-6 rounded-md">
 <FaBan className="mr-2 text-red-600" size={24} />
 <span className="font-medium">Currently, this job is closed. Please check back later for new openings.</span>
</div>
: <section>
      <div className="flex items-center text-xs text-gray-600 mb-4">
        <FaExclamationCircle className="mr-2 text-blue-500 hidden md:block text-justify" />
        <p>
          Please ensure that you are eligible for this position. Then, enter your email below and click 'Apply' to agree.
        </p>
      </div>

      <div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-6">
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={handleEmailChange}
          className="bg-white w-full sm:w-auto flex-1 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <button
          onClick={handleAgree}
          disabled={isLoading}
          className={`w-full sm:w-auto bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700 transition-colors flex items-center justify-center ${
            isLoading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isLoading ? (
            <>
              <FaSpinner className="animate-spin mr-2" />
              Applying...
            </>
          ) : (
            'Apply'
          )}
        </button>
      </div>

      <p className="mt-4 mb-2 text-lg font-semibold">Job Description:</p>
      <div
        className="mb-6 text-gray-800"
        dangerouslySetInnerHTML={{ __html: jobDetails.job_description }}
      />
</section>
}
    </div>
  );
};

export default ApplyJobApplication;

const JobAdditionalDetails = ({ job }) => (
  <div className="flex flex-col md:flex-row mb-2 gap-2">
    <DetailItem label="Experience Required" value={`${job.experience_required} years`} icon={<FaClock />} />
    <DetailItem label="Location" value={job.location} icon={<FaMapMarkerAlt />} />
    <DetailItem label="Employment Type" value={job.employment_type} icon={<FaUserTie />} />
    <DetailItem label="Job Status" value={job.job_status} icon={job.job_status === 'Closed' ? <FaTimesCircle /> : <FaCheckCircle />} />
  </div>
);

const DetailItem = ({ label, value, icon }) => (
  <div title={label} className="flex items-center p-2 rounded-md bg-gray-100 shadow-sm">
    <span className="mr-3 text-blue-600">{icon}</span>
    <span className="text-blue-900 font-medium">{value}</span>
  </div>
);
